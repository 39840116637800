
@import "./../scss/required";

.brand-logo {
    > svg {
        width: 80px;
        height: 30px;

        @include media-breakpoint-down(lg) {
            width: 30px;
        }
    }
}
